@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

/*
  Note that any styles you add here:
  1. Will be displayed when you run npm start.
  2. Will *NOT* be included in your npm package.
  (index.js and index.scss are excluded from the npm package).
  So this is a handy way to test global styles that may exist where you component will be consumed.
*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.inputymm{
  margin-bottom: 0px !important;
  justify-content: start !important;
}