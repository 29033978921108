/*
 *
 * Base styles for the starter kit.
 *
 */

body {
  font-family: $font-family-base;
}

.btn-separation {
  margin-right: 5px;
}

button:focus {
  outline: 0;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

*[hidden] {
  display: none !important;
}

/**
 * = LAYOUT Container styles
 * ------------------------------------------------------------------ */
#wrapper {
  width: 100%;
  min-width: 1230px;
}

.page-wrapper {
  position: absolute;
  overflow: auto;
  right: 0;
  bottom: 0;
  left: 0;
  top: 80px;
  padding: 16px;
  min-width: 1230px;
  background-color: $Titanium200;
  h3 {
    margin-bottom: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .xmm-center-container {
    width: 1198px;
    min-width: 1198px;
    margin: auto;
  }
}

.xmm-content-adjust {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.card__dropdown-button:hover {
  opacity: 1;
}
/**
 * = Generic styles can be used to Form elements
 * ------------------------------------------------------------------ */
.bold {
  font-weight: 600;
}
.hand-cursor {
  cursor: pointer;
}
.float-right {
  float: right !important;
}
.xmmadminfloat-right-red {
  float: right !important;
  color: red;
  fill: red;
}
.xmmadmin-red-indicator {
  background-color: red;
}
.xmmadmin-green-indicator{
  background-color: green;
}
.xmmadminfloat-right-green {
  float: right !important;
  color: green;
  fill: green;
}
.label-info {
  color: $Cobalt800;
  background-color: $white;
  font-size: 12px;
  margin-right: 4px;
  display: inline-block;
}
.xmm-label-info {
  color: $Cobalt800;
  font-size: 12px;
  margin-right: 4px;
  display: inline-block;
}
.full-col {
  padding: 0 !important;
}
.full-row.row {
  margin-left: -30px;
  margin-right: -30px;
}
.full-padding-right-col {
  padding: 0 !important;
  padding-right: 10px !important;
}
.xmm-slider-tab-scroll {
  height: calc(100vh - 142px);
  overflow: overlay;
}
.xmm-slider-external-pricing-scroll {
  height: calc(100vh - 142px);
  overflow: overlay;
}
.xmm-external-pricing-grid {
  width: 98%
}
.flex-content-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 10px 4px 6px;
  border: 0px solid #000;
}
.xmm-border-line {
    border-left: 1px solid $Titanium500;
}
.xmm-md-input{
  width: 200px;
}
.content-header + .content-header{
  margin-top: -8px;
}
.content-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  .xmm-form-header {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    & > *:not(:last-child) {
      margin-right: 8px;
      // margin-bottom: 0px;
    }
    .dropdown-toggle.btn.xmm-dotted-dropdown,
    .dropdown.open .dropdown-toggle.btn.xmm-dotted-dropdown {
      margin: 0;
    }
    // .btn {
    //   margin-bottom: 12px;
    // }
  }
}

.dropdown.xmm-dotted-dropdown .dropdown-toggle.btn,
.dropdown.open.xmm-dotted-dropdown .dropdown-toggle.btn {
  border: none;
  color: $Titanium700;
  margin: 0 8px;
  background-color: transparent;
  .caret {
    display: none;
  }
  &:focus,
  &:hover {
    background-color: $Titanium400;
  }
}
.xmm-header-right {
  .dropdown {
    float: right;
  }
  .btn {
    margin: 0 8px;
  }
  .cx-select {
    margin-left: 8px;
  }
}
.cx-select svg.icon-keyboard-arrow-down {
  z-index: 0 !important;
}

/**
 * = Message block styles
 * ------------------------------------------------------------------ */
.xmm-msg {
  display: inline-block;
  padding: 0 8px;
  font-size: 13px;
  color: $Titanium700;
}
.xmm-error-msg {
  font-size: 12px;
  color: $Ferrari500;
}
.xmm-success-msg {
  color: $Cobalt600;
}
.xmm-red-label {
  color: $Ferrari500;
}
.xmm-tab-bar {
  margin-top: 8px;
  margin-bottom: 18px;
  text-align: right;
}
.no-margin {
  margin: 0px !important;
}
.form-group.has-error .rbt .form-control,
.form-group.has-error .rbt input.form-control {
  border-width: 2px;
}
.form-group .rbt .form-control.focus,
.form-group .rbt input.form-control.focus {
  border-width: 1px;
}
.xmm-footer {
  position: absolute;
  bottom: 0;
  height: 30px;
  color: $Titanium700;
  font-size: 11px;
  display: none;
  strong {
    font-weight: 600;
    color: $Titanium800;
    font-size: 12px;
  }
}

.xmm-findopcode-header {
  margin-bottom: 8px;
  a,
  a:hover,
  a:focus {
    color: $Titanium800;
  }
}
.xmm-search-opcode-cell {
  input {
    width: 100%;
    padding-right: 24px;
    padding-left: 8px;
    height: 28px;
    position: absolute;
    left: 0;
  }
  .icon-search {
    position: absolute;
    z-index: 12;
    right: 4px;
    color: $Cobalt500;
  }
}
.xmm-override-cell {
  span {
    position: absolute;
    z-index: 12;
    top: 3px;
    right: 4px;
  }
}
.xmm-string-link.btn-link {
  padding: 0;
}

.scroll-enabled .dropdown-menu {
  overflow: auto;
  max-height: 120px;
}

.xmm-block-underline {
  width: 100%;
  display: block;
  border-bottom: 1px solid #aaaaaa;
}
.xmm-filter-section .form-group,
.xmm-inline-form .form-group {
  display: inline-block;
  margin-left: 15px;
}
.list-group {
  margin: 20px 0;
  .xmm-section-separator {
    border-bottom: 1px solid $Titanium500;
    margin: 8px 0;
  }
}
.xmm-tab-scroll-content {
  @include calc-dynamic(height, "100vh - 110px");
  overflow-y: auto;
  overflow-x: hidden;
}
.xmm-vehiclegroup-accordion .cx-accordion-body {
  padding-bottom: 150px;
}
.xmm-accordion-header {
  height: 35px;
  display: flex;
  align-items: center;
  .status-box {
    width: 100px;
  }
}
.cx-alert .cx-loadingIndicator {
  display: inline-block;
  margin-right: 16px;
}

.xmm-menu-type-card {
  display: flex;
  align-items: center;
  div {
    flex: 1;
    padding: 0 8px;
  }
  .xmm-card-description {
    flex: 25;
    .xmm_inherit_from {
      font-size: 10px;
      color: $Titanium600;
      margin-left: 10px;
      display: block;
    }
  }
  .cx-switch {
    margin-bottom: 0;
  }
  .menu-types-oem {
    color: $Krypton700;
  }
  .xmm-circle-image-container {
    min-width: 100px;
    height: 40px;
    padding-top: 5px;
    margin-right: 12px;
    text-align: center;
    vertical-align: middle;
  }
  .xmm-circle-image {
    height: 31px;
    mix-blend-mode: multiply;
  }
}
.menu-types-checkboxes {
  display: inline-flex;
  margin-top: 0px;
  margin-bottom: 8px;
}

.xmm-menu-type-header {
  width: 100%;
  max-width: 1400px;
}
.xmm-menu-type-select {
  width: 300px;
}
.xmm-filter-type-select {
  width: 150px;
}
.card.card--dropdown.menu-type-disabled {
  background-color: $Titanium300;
}
.ag-theme-balham .ag-popup .selector-container {
  min-width: 300px;
}
.selector-panel {
  position: absolute;
  z-index: 10;
  border: 1px solid $Titanium800;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 8px;
  width: 100%;
  background-color: $white;
  border-color: $Titanium500;
}
.selector-panel-top {
  top: -100px;
}
.selector-panel-down {
  top: 32px;
}
.selector-panel-up {
  top: -227px;
}
.vehicle-group-selector-panel-up {
  top: -232px;
}
.vehicle-group-selector-editor-up {
  top: -260px;
}
.selector-container {
  margin-bottom: 12px;
}
.selector-container-editor {
  margin-bottom: 0px;
}
.selector-container,
.selector-container-editor {
  position: relative;
  .btn.btn--icon svg {
    color: $Titanium800;
  }
  .xmm-input-search:before {
    top: 10px;
  }
}
.interval-selector-scroll {
  overflow-y: auto;
  height: 140px;
}
.vehicle-selector-scroll {
  overflow-y: auto;
  height: 115px;
}
.selector-list-group {
  margin-left: 0px;
  font-size: 12px;
  line-height: 24px;
  color: $Titanium800;
  margin-bottom: 0;
  padding: 0;
  .list-group-item {
    margin-left: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
    padding: 0 8px;
    &:hover {
      background-color: $Cobalt200;
      cursor: pointer;
    }
    &.active {
      background-color: $Cobalt500;
    }
    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.selector-search {
  width: 100%;
  margin-bottom: 2px;
  input {
    color: transparent;
    text-shadow: 0 0 0 $Titanium800;
    font-size: 13px;
    cursor: pointer;
  }
}
.selector-heading {
  color: $Titanium700;
  font-size: 10px;
}
.selector-full-width {
  width: 100%;
  display: inline-block;
}
.selector-separator {
  margin-top: 1px;
  margin-bottom: 4px;
  border: 0;
  border-top: 1px solid $Titanium600;
}
.selector-edit-hours {
  width: 100%;
  input {
    color: transparent;
    text-shadow: 0 0 0 $Titanium800;
    font-size: 13px;
    cursor: pointer;
  }
}
.xmm-cell-editor {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.xmm-grid-price [ref="eCellValue"] {
  text-align: right;
  width: 100%;
  height: 100%;
}
.popover.xmm-publish-popover {
  max-width: 320px;
  width: 320px;
}
/**
 * = Tooltip delay styles
 * ------------------------------------------------------------------ */

.xmm-tooltip-delay.tooltip.in {
  opacity: 1;
  transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -webkit-transition: opacity 1s ease-in;
}

.xmm-tooltip-delay.tooltip {
  opacity: 0;
}

.xmm-truncate-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * = DealerMenu page styles - move to new scss file
 * ------------------------------------------------------------------ */

.xmm-dealermenu-make-label {
  display: inline-block;
  width: 200px;
}
.xmm-dealermenu-copy-to {
  margin: 0 0 !important;
}
.xmm-rule-dropdown-menu {
  height: 25px;
  background-color: $Cobalt200;
}
.xmm-rule-dropdown-menu-renderer {
  height: 25px;
  padding-left: 8px;
}
.xmm-select-menu-label {
  color: $Titanium700;
}
.xmm-creating-rules-label {
  color: $Titanium700;
  font-size: 10px;
  font-style: italic;
  margin-right: 8px;
}
.cx-accordion-title {
  a span {
    width: 100%;
    .badge {
      width: auto;
      margin-right: 12px;
    }
  }
}
.xmm-picky-schedule-groupby {
  position: relative;
  width: 255px;
  max-width: 300px;
}
.xmm-picky-menu-types {
  position: relative;
  width: 100%;
  // max-width: 100%;
}
.xmm-grid-cell-error {
  cursor: pointer;
  background-color: $Ferrari200;
}
.xmm-grid-cell-checkbox [ref="eCellValue"] {
  width: 100%;
  height: 100%;
}
.xmm-grid-cell-bulkedited {
  // cursor: pointer;
  background-color: $Krypton200;
}
/* css for testing */
.valid-css {
  color: #000;
  background-color: $Cobalt500;
}
.error-css {
  color: #fff;
  background-color: $Ferrari300;
}
.popover.xmm-add-external-popover {
  width: 450px;
  z-index: 9999;
  max-width: 450px;
}
.popover.xmm-add-user-popover {
  width: 350px;
  z-index: 9999;
  max-width: 350px;
}
.badge-cobalt {
  width: auto;
  color: #fff;
  background-color: $Cobalt500;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
}
.badge-count {
  width: auto;
  color: White;
  background-color: $Titanium700;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
}
/* TODO - placeholder to customize popover content DOM */
.xmm-popover-content {
  max-width: 300px;
  display: flex;
  align-items: center;
  align-content: center;
  border: 1px solid $Titanium500;
}
.xmm-dealermenu-inherited-rules {
  color: $Titanium700;
  font-style: italic;
}
.xmm-override-msg {
  .xmm-override-error {
    color: white;
    fill: $Ferrari500;
    vertical-align: middle;
  }
  .xmm-override-warn {
    color: black;
    fill: #ffaa00;
    vertical-align: middle;
  }
  .xmm-override-info {
    color: white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}
/* Status box with cx loading icon */
.xmm-status-box {
  height: auto;
  width: auto;
  display: inline-block;
  margin-top: 4px;
  margin-right: 8px;
  .svgicon {
    margin: 0 0;
    padding: 0 0;
    vertical-align: middle;
  }
  &.success {
    color: $Krypton700;
    .svgicon {
      fill: $Krypton700;
    }
  }
  &.error {
    color: $Ferrari600;
    .svgicon {
      fill: $Ferrari600;
    }
  }
  &.pending {
    color: $Cobalt600;
    .svgicon {
      fill: $Cobalt600;
    }
  }
  &.info {
    color: $Cobalt600;
    .svgicon {
      fill: $Cobalt600;
    }
  }
  &.text {
    color: $Titanium700;
  }
  &.fade-out {
    animation: fadeOutFrames 5s;
  }
  @keyframes fadeOutFrames {
    0% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
.justify-tooltip.tooltip .tooltip-inner{
  text-align: left;
}
.info-blue {
  color:$white;
  fill: $Cobalt500;
  vertical-align: middle;
  cursor: pointer !important;
}
.xmm-popover-badge {
  display: inline-block;
  margin: 0 10px;
  .badge {
    padding: 4px 16px;
  }
}

.xmm-slider-popover-badge {
  display: inline-block;
  margin: 0 -20px;
  .badge {
    padding: 4px 16px;
  }
}
.xmm-grid-cell-textarea [ref="eCellValue"] {
  width: 100%;
  height: 100%;
}
.xmm-grid-editor-textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  padding: 4px;
}
.air-apps-container {
  width: 100%;
  text-align: center;
}
.air-apps-header {
  display: flex;
  // text-align: center;
  margin: 10px;
  // width: 100%;
  padding-top: 10px;
}
.air-app-icon-box {
  border-width: 2px;
  border-radius: 5px;
  border-color: black;
  border-style: solid;
  margin: 25px;
  padding: 10px;
  width: 200px;
  text-align: center;
  cursor: pointer;
}
.air-app-icon-box:hover {
  opacity: 0.9;
  box-shadow: 3px 3px 3px 3px $Titanium700;
}
.air-app-icon {
  // width: 96px;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  mix-blend-mode: multiply;
}
.air-app-label {
  // margin-top: 10px;
  display: block;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}
.air-app-download-toggle {
  display: flex;
  // text-align: center;
  // vertical-align: middle;
  align-items: center;
  cursor: pointer;
}
.air-app-download-svgicon {
  margin: 0 0;
  padding: 0 0;
  -webkit-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}
.air-app-download-box {
  border-width: 2px;
  border-radius: 5px;
  border-color: black;
  border-style: solid;
  margin: 25px;
  padding: 25px;
  width: 100%;
  // text-align: center;
  // cursor: pointer;
  .link {
    padding: 10px 20px 5px;
  }
  .last-link {
    padding: 10px 20px 10px;
  }
  .link-end {
    padding: 0px 0px 5px
  }
}
.air-app-install-label {
  margin-top: 5px;
  font-weight: bold;
}

/* push-packages page */
.xmm-dealer-select {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .dealer-div {
    height: auto;
    width: 100%;
    align-items: flex-start;
  }
  .launch-icon {
    margin: 0px 8px;
    padding-top: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
