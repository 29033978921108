.xmm-error-input {
  input,
  input:focus {
    border-color: $Ferrari500;
    border-width: 2px;
  }
}
.xmm-input-error-msg {
  margin-top: -12px;
  margin-bottom: 8px;
  font-size: 12px;
  .icon-error {
    fill: $Ferrari500;
    vertical-align: middle;
    margin-right: 4px;
  }
}
.xmm-warning-msg {
  margin-top: -12px;
  margin-bottom: 8px;
  // vertical-align: middle;
  font-size: 11px;
  .icon-warning {
    // fill: $GialloE2;
    vertical-align: middle;
    margin-right: 4px;
  }
}
/* style for cx-field error icon */
.help-block__wrapper{
  display: flex;
  .svgicon{
    min-width: 24px;
  }
}

.xmm-search-addon {
  .addon-wrapper {
    padding: 0 4px;
  }
}
.input-group {
  width: 100%;
}
.xmm-input-search {
  position: relative;
  display: block;
  padding-left: 6px;
  &.full-row,
  &.full-row input {
    width: 100%;
    margin: 0;
    margin-bottom: 8px;
  }
}
.xmm-input-search:before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f002";
  font-style: normal;
  font-weight: 600;
  text-decoration: inherit;
  color: $Titanium600;
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 5px;
}
.xmm-input-search.xmm-label-space:before {
  top: 25px;
}

.xmm-input {
  padding: 5px 8px 4px;
  height: 32px;
  background-color: $white;
  border: 1px solid $Titanium600;
  @include border-radius(4px);
  outline: 0;
  padding-right: 24px;
  position: relative;
  &:focus {
    border: 1px solid $Cobalt500;
  }
  width: 100%;
}
.xmm-dealermenu-group-by {
  padding-left: 8px;
  width: 220px;
  @include bp-medium{
    width: 140px;
  }
}
.cx-select select {
  cursor: pointer;
}
.xmm-form-label {
  margin: 1px 0 16px;
  min-height: 16px;
}
.input-side-by-side.long-label label{
  width: 35%;
}
.input-side-by-side.xmm-nowrap .xmm-nowrap-label{
  width: 40px;
  white-space: nowrap;
  color: $Titanium800;
}
.xmm-label{
  margin: 4px 8px;
  min-height: 16px;
  font-size: 14px;
  color: $Titanium700;
}
.xmm-vertical-resize textarea {
  resize: vertical;
}
.xmm-toogle-label{
  justify-content: flex-start;
  label{
    margin-right: 8px;
  }
}
.dropdown-item hr {
  margin: 0;
}

.searchableSelectInput .rbt-menu.dropdown-menu .dropdown-item {
  padding: 4px 8px !important;
}

.xmm-scrollable-select .rbt-input-multi {
  height: auto;
  max-height: 80px;
  overflow: auto;
}
.xmm-scrollable-select .searchableSelectInput--multi .rbt-input.rbt-input-multi.form-control{
  padding: 6px 40px 4px 8px;
  ::placeholder{
    -webkit-text-security: none;
    font-weight: 400;
  }
}
.form-control{
  @include placeholder{
    color: $Titanium800 !important;
    font-weight: 400 !important;
  }
}
.xmm-publish-form {
  padding: 0 32px;
}
.xmm-radio-form {
  .radio + .radio {
    margin-top: 20px;
  }
  &.SingleDatePicker,
  &.TimePicker {
    margin-top: 40px;
  }
}
.radio label,
.checkbox label {
  width: fit-content;
}
.TimePicker .timeSlot-wrapper--direction-down,
.singleDayPickerController--direction-down .DayPicker {
  top: 36px;
}
.xmm-input-full-width{
  width: 100%;
  .control-label{
    text-align: left;
  }
}

.xmm-left-label{
  display: flex;
  .control-label{
    width:auto;
    text-align: left;
  }
  .select-wrapper{
    width: 100%;
  }
}
.xmm-empty-toggle{
  width: 54px;
}
.xmm-fixed-row{
  display: flex;
  .input-wrapper{
    min-width: 150px;
  }
  .input-wrapper + .input-wrapper {
    margin-left: 12px;
  }
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 32px;
  color: #2B6CDD !important;
  font-weight: 300;
}
.xmm-datepicker {
   margin-top: 48px;
 }
