@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.picky__input {
  text-align: left;
}

.pickyMultiSelect-dropdown-icon {
  pointer-events: none;
  position: absolute;
  top: 28px;
  right: 20px;
}
.pickyMultiSelect-dropdown-no-label-icon {
  pointer-events: none;
  position: absolute;
  top: 5px;
  right: 20px;
}

.picky__dropdown {
  border: 1px solid #cacfd9;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  cursor: pointer;
  .option{
    padding: 4px 8px;
    // display: inline-flex;
    // align-items: center;
    &:hover{
      color: #ffffff;
      background-color: #2B6BDD;
    }
    input[type="checkbox"]{
      cursor: pointer;
      margin: 0 8px 0 0;
      vertical-align: middle;
    }
  }
}
.picky__filter__input {
  border: 1px solid #cacfd9;
  border-radius: 4px;
  margin: 4px;
  width: -webkit-fill-available;
  padding: 4px;
}
