.ops-sticky-section{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $white;
}
.ops-form-section{
  display: flex;
  align-items: flex-end;
  .form-group{
    margin-bottom: 0px;
  }
  i{
    margin-left: 16px;
  }
  .help-block__wrapper{
    position: absolute;
  }
}
.ops-vehicle-card{
  display: flex;
  align-items: flex-end;
  gap: 32px;
  border-right: 1px solid $Titanium600;
}
.ops-labor-panel{
  align-items: center;
    display: flex;
  .panel{
    margin-bottom: 0;
  }
}
.ops-labor-card{
  display: flex;
  .form-group {
    margin-bottom: 0px;
  }
}
.ops-results-grid{
  margin-top: 32px;
  h4{
    margin: 16px 0 8px;
  }
  .ag-grid-container{
    margin-bottom: 8px;
  }
}
.ops-preview-pane{
  hr{
    margin-bottom: 12px;
  }
}
.ops-error-msg {
  font-size: 12px;
  color: $Ferrari500;
}
.ops-notes {
  .info {
    color:$white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}
.btn-default:focus{
  color: $Cobalt500 !important;
  border-color: $Cobalt500 !important;
}

/* loading overlay styles */
.ops-loader-msg{
    font-size: 15px;
    color: $Titanium700;
}
.ops-special-part-number {
  .ops-part-number-warn {
    color: $black;
    fill: $Giallo700;
    vertical-align: middle;
  }
}

.ops-preview-labels {
  position: relative;
  width: 200px;
  max-width: 450px;
  padding-right: 10px;
}
.ops-preview-category-group-filter {
  position: relative;
  width: 250px;
  max-width: 450px;
  padding-right: 10px;
}
.ops-linked-services {
  display: inline;
  .info {
    color:$white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}
