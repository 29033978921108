/* Navigation
 *
 * Styling for the navigation bar.
 *
 */

.nav-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: 1230px;
  height: 40px;
  top: 40px;
  right: auto;
  color: $Titanium700;
  background-color: $white;
  padding: 8px;
  border-bottom: 1px solid $Titanium500;
  .cx-drawer{
    color: $Titanium800;
  }
}
.nav {
  display: inline-block;
}
.nav__pills.nav {
  display: inline-block;
  &__link {
    font-size: inherit;
  }

  &__pills > li {
    display: inline-block;
  }

  > &__pills > li + li,
  > .nav-stacked > li + li {
    margin-top: 0;
  }

  &__pills > li > a {
    border-radius: 0;
    color: $Titanium800;
    background-color: transparent;
    padding: 0 10px;
    font-size: 14px;
    .xmm-nav-dropdown{
      display: flex;
      align-items: center;
    }
    &:hover {
      color: $Cobalt500;
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
    .caret{
      display: none;
    }
  }

  &__pills > li:first-child > a.nav__link,
  &__pills > li:first-child > a.nav__link--active {
    padding-left: 4px;
  }

  &__pills > li > a.nav__link--active {
    color: $Cobalt500;
    background-color: transparent;

    &:hover {
      color: $Cobalt500;
      background-color: transparent;
    }
  }
}
.nav__link--active.dropdown .dropdown-toggle {
  color: $Cobalt500;
  background-color: transparent;
}
.nav__link--active.dropdown .dropdown-menu li {
  .nav__link--active {
    color: $Cobalt500;
    background-color: transparent;
  }
  .nav__link {
    color: $Titanium800;
    background-color: transparent;
  }
}
.nav__link--active.dropdown .dropdown-menu li:hover,
.nav__link--active.dropdown .dropdown-menu li:focus {
  background-color: $Titanium300;
}

.nav__link.dropdown .dropdown-menu li {
  .nav__link {
    background-color: transparent;
    color: $Titanium800;
  }
}
.nav__link.dropdown .dropdown-menu li:hover,
.nav__link.dropdown .dropdown-menu li:focus {
  background-color: $Titanium300;
}
