.ops-sticky-section{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: $white;
}
.ops-form-section{
  display: flex;
  align-items: flex-end;
  .form-group{
    margin-bottom: 0px;
  }
  i{
    margin-left: 16px;
  }
  .help-block__wrapper{
    position: absolute;
  }
}
.ops-vehicle-card{
  display: flex;
  align-items: flex-end;
  gap: 32px;
  border-right: 1px solid $Titanium600;
}
.ops-labor-panel{
  align-items: center;
    display: flex;
  .panel{
    margin-bottom: 0;
  }
}
.ops-labor-card{
  display: flex;
  .form-group {
    margin-bottom: 0px;
  }
}
.ops-results-grid{
  margin-top: 16px;
  h4{
    margin: 16px 0 8px;
  }
  .ag-grid-container{
    margin-bottom: 8px;
  }
}
.ops-preview-pane{
  hr{
    margin-bottom: 12px;
  }
}
.ops-error-msg {
  font-size: 12px;
  color: $Ferrari500;
}
.ops-notes {
  .info {
    color:$white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}
.parts-instock {
  color:gray;
  vertical-align: middle;
  cursor: pointer;
  // width: 100%;
  &.has-superseded-parts {
    cursor: pointer !important;
  }
}
.list-tooltip.tooltip .tooltip-inner{
  text-align: left;
  max-width: 450px;
  padding: 8px 8px;
  ul{
    padding-inline-start: 15px;
    text-align: left;
    margin-bottom: 0;
  }
}
.btn-default:focus{
  color: $Cobalt500 !important;
  border-color: $Cobalt500 !important;
}

/* loading overlay styles */
.ops-loader-msg{
    font-size: 15px;
    color: $Titanium700;
}
.ops-part-description {
  .ops-description {
    padding-right: 5px;
  }
  .badge {
    margin-right: 5px;
  }
}
.ops-special-part-number {
  display: grid;
  width: 176px;
  grid-template-columns: 1fr auto 18px;
  gap: 4px;
  .ops-part-number-warn {
    color: $black;
    fill: $Giallo700;
    vertical-align: middle;
    cursor: pointer;
  }
}
.ops-na-parts {
  // max-width: 320px;
  // max-height: 200px;
  // width: 320px;
  .info {
    color:$white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}

.popover.ops-superseded-parts-popover,
.popover.ops-na-parts-popover {
  max-width: unset;
  width: auto;
  height: auto;
}
.ops-na-table-container {
  max-height: 200px;
  min-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.ops-ant-table-thead > tr > th {
  background: transparent;
  transition: background 0.3s ease;
  text-align: left;
  color: $Titanium700;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
}
.ops-ant-table-row > td:last-child {
  border-right: 2px solid $Titanium500;
}
.ops-ant-table-row > td {
  border-top: 1px solid $Titanium500;
  background-color: $white;
  padding: 4px 8px;
  font-size: 12px;
}
.ops-ant-table-row > td:first-child {
  border-left: 1px solid $Titanium500;
}
.ops-ant-table-row:last-child > td {
  border-bottom: 1px solid $Titanium500;
}
.parts-supersession-row {
  cursor: pointer;
}

.ops-preview-labels {
  position: relative;
  width: 200px;
  max-width: 450px;
  padding-right: 10px;
}
.ops-preview-category-group-filter {
  position: relative;
  width: 250px;
  max-width: 450px;
  padding-right: 10px;
}
.ops-linked-services {
  display: inline;
  .info {
    color:$white;
    fill: $Cobalt500;
    vertical-align: middle;
    cursor: pointer !important;
  }
}
.select-container-vehicle-attributes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  div{
    padding: 10px 10px 0px 0px;
    min-width: 250px;
  }
  .select-container {
    padding-top: 0px;
  }

}