.ag-grid-container.ops-parts-grid{
  height: calc(100vh - 400px);
}
.ag-grid-container.ops-auto-height{
  height: auto;
}
.ag-grid-container.ops-scroll-x-hidden{
  // WORKAROUND to fix horizontal scrollbar issue with Labor and Parts Grids
  .ag-center-cols-viewport {
    width: 100% !important;
    overflow-x: hidden !important;
  }
}
.ag-grid-container.no-striped-grid{
  .ag-row, .ag-row-odd{
    background-color: $white !important;
  }
}

/* loading overlay styles */
.ops-loader-msg{
    font-size: 15px;
    color: $Titanium700;
}

/* styles related to Dealer catalog preview table */
.ops-center-content {
  width: 100%;
  text-align: center;
}

.ops-table {
  .op-row {
    max-height: 40px;
    display: flex;
    overflow: hidden;
    @include transition(max-height 0.4s ease);
    &.modified {
      max-height: 500px;
    }
    &:hover {
      background-color: $Titanium200;
    }

    .op-name {
      flex: 1;
      border-bottom: 1px solid $Titanium500;
      align-items: flex-end;
      justify-content: space-between;
      padding: 4px 0;
      .top-level {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
      }
      .op-icon {
        margin: 0 8px;
        min-width: 24px;
        cursor: pointer;
      }
      .op-title {
        line-height: 35px;
        flex: 1;
        display: grid;
        // dynamic min-width, max-width will be updated in UI
      }
      .op-price {
        width: 100px;
        text-align: right;
        padding-right: 4px;
        .svgicon {
          vertical-align: bottom;
        }
      }
      // Add two columns for Individual operations table
      .op-opcode {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .op-consumer {
        width: 100px;
      }
    }
    .op-services {
      display: flex;
    }
    .op-status {
      width: 180px;
      margin-left: 24px;
      text-align: center;
      padding: 9px 0;
      font-weight: 500;
      border-bottom: 1px solid $Titanium500;
      @include bp-medium {
        width: 120px;
      }
    }
  }
}
/**
 * = Parts Table styles
 * ------------------------------------------------------------------ */
 .table-parts {
  width: 100%;
  font-size: 12px;
  margin: 4px 4px 4px 36px;
  max-width: 650px;
  @include bp-medium {
    max-width: 560px;
  }
  tr td {
    padding: 4px;
  }
  td {
    text-align: left;
    &:first-child {
      width: 120px;
      strong {
        padding: 0 8px;
      }
    }
  }

  &.parts-border {
    tr td {
      border-bottom: 1px solid $Titanium500;
      &:first-child {
        border-bottom: 0;
      }
    }
    tr:last-child td {
      border-bottom: 0;
    }
    tr:first-child td {
      color: $Titanium700;
      &:first-child {
        color: $black;
      }
    }
    &:last-child td {
      &:nth-child(2) {
        width: 180px;
      }
      &:nth-last-child(-n + 3) {
        width: 55px;
        text-align: right;
      }
    }
  }
}
.table-parts + .table-parts {
  border-top: 1px solid $Titanium500;
  tr:first-child td {
    padding-top: 8px;
  }
}
