/* Header
 *
 * Styling for the header bar which is an instace of Navbar.
 * https://react-bootstrap.github.io/components.html#navigation
 *
 */
.header-bar.navbar {
  background-color: $Cobalt800;
  background-image: none;
  box-shadow: none;
  margin-bottom: 0;
  min-height: 40px;
  border: 0;
  z-index: 2;
  .container-fluid{
    padding-right: 0;

  }
  & &__nav-header {
    float: left;
    color: $white;
    user-select: none;
  }
  & #IconMenu{
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -4px;
    cursor: pointer;
  }
  .header-bar__nav-logo {
    height: auto;
    border-left: none;
    padding: 0 16px;
    display: inline-block;
  }
  .navbar-brand, .navbar-brand:hover{
    height: 40px;
    color: $white;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 500;
  }
  &.navbar-default .navbar-nav > li > a{
    color: $white;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
  }

  &__nav-brand > img {
    display: inline-block;
  }

  &__nav-right.nav.navbar-nav {
    float: right;
  }

  & &__nav-right > li.dropdown > a {
    color: $white;
  }

  & &__nav-right > li.open.dropdown > a {
    background-color: $white;
    color: $white;
  }
  .icon-nav.navbar-nav{
    display: flex;
    align-items: center;
    float: right;
    .icon-people{
      margin-right: 8px; 
      margin-top: 2px;
    }
  }
  .dropdown-menu{
    min-width: 100px;
    right: 0;
    left: unset;
    li.disabled>a:hover, li.disabled>a:focus{
      color: $Titanium500;
    }
    li>a:hover, li>a:focus{
      color: $white;
      outline: none;
    }
  }

  @media (max-width: $screen-xs-max) {
    &__nav-brand > img {
      display: none;
    }

    & .navbar-nav .open .dropdown-menu {
      position: absolute;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      right: 0;
      left: auto;
    }

  }
  .header-icon{
    fill: $white;
    float: left;
  }
  .header-catalogs {
    float: right;
    margin-top: 8px;
    margin-right: 18px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .header-catalogs-button {
    color: black;
    vertical-align: middle;
  }
  .header-catalogs-button-selected {
    color: black;
    background-color: $Cobalt300;
    vertical-align: middle;
  }
  .app-name-block {
    color: #fff;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    line-height: 40px;
    float: right;
  }
  .user-name-block {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: block;
    line-height: 40px;
    float: right;
  }
  .header-manage-catalogs {
    color: white;
    font-size: 10pt;
    vertical-align: middle;
    float: right;
  }
  .header-manage-catalogs-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
  }
  .header-manage-badge {
    color: white;
    font-size: 10pt;
    vertical-align: middle;
    float: right;
    margin-top: 10px;
  }
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover{
  padding: 8px 16px;
  color: $white;
}
.header-bar.navbar.navbar-default .navbar-nav .navbar-dropdown-icon {
  .dropdown-toggle{
    padding: 8px;
    display: flex;
    align-items: center;
  }
  .caret{
    display: none;
  }
}
.header-bar.navbar.navbar-default .navbar-nav .navbar-icon-caret {
  .dropdown-toggle{
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75px;
    .nav-language-text{
      vertical-align: middle;
    }
  }
  &.no-dropdown{
    .caret{
      display: none;
    }
    .dropdown-menu{
      display: none;
    }
  }
}
